import SuperTokens from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";

SuperTokens.init({
  appInfo: {
    appName: "pockettally",
    apiDomain: import.meta.env.VITE_API_DOMAIN,
    websiteDomain: import.meta.env.VITE_SITE_DOMAIN,
    apiBasePath: "/auth",
    websiteBasePath: "/login",
  },
  termsOfServiceLink: "/terms",
  privacyPolicyLink: "/privacy",
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS" && context.newSessionCreated) {
      if (context.redirectToPath !== undefined) {
        return context.redirectToPath;
      }
      return "/home"; // New users will be redirected to complete account
    }
    return undefined;
  },
  recipeList: [
    Passwordless.init({
      contactMethod: "EMAIL",
    }),
    Session.init(),
  ],
});
