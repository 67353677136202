import Hotjar from "@hotjar/browser";
import Session from "supertokens-auth-react/recipe/session";

declare global {
  interface Window {
    hj: any;
  }
}

const hotjarVersion = 6;

export function HotjarInit() {
  if (import.meta.env.PROD) {
    Hotjar.init(import.meta.env.VITE_HOTJAR_SITE_ID, hotjarVersion);
  }
}

function HotjarInitialised() {
  return typeof window !== "undefined" && typeof window?.hj === "function";
}

export async function HotjarIdentify() {
  if (HotjarInitialised()) {
    const userID = await Session.getUserId();
    Hotjar.identify(userID, {});
  }
}

HotjarInit();
