import { GameStateT } from "./routes/games/create/type";

abstract class BaseStore<T> {
  abstract getKey(id: string): string;

  set(id: string, state: T) {
    localStorage.setItem(this.getKey(id), JSON.stringify(state));
  }

  get(id: string): T | undefined {
    let state = localStorage.getItem(this.getKey(id));
    if (state) return JSON.parse(state);
  }
}

export class GameStore extends BaseStore<GameStateT> {
  getKey(id: string): string {
    return `game_${id}`;
  }
}

export class GameScorecardStore extends BaseStore<string> {
  // Stores a scorecard template ID against a game ID
  // This is used when creating the first scorecard group if the user does not yet have a template
  getKey(id: string): string {
    return `game_scorecard_${id}`;
  }
}
