import { GameType } from "@/api/game";
import { GameStore } from "@/storage";
import { useParams } from "react-router-dom";
import { TallyEdit } from "../tally/edit";
import ScorecardEdit from "../scorecard/edit";

export function TrackerEdit() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyEdit />;
    case GameType.Scorecard:
      return <ScorecardEdit />;
  }
}
