import { ActionFunctionArgs } from "react-router-dom";
import { GroupRes } from "./game";
import { TrackerBase } from "./tracker";
import { apiFetch } from "./utils";

export type TallyScoreRes = {
  team_id: string;
  score: number;
};
export type TallyRes = TrackerBase & {
  scores: TallyScoreRes[];
};
export type TallyGroupRes = {
  group: GroupRes;
  tallies: TallyRes[];
};

type TallyUpdate = {
  scores: { team: string; score: number };
};

export async function getTally({
  params,
}: ActionFunctionArgs): Promise<TallyGroupRes | undefined> {
  const response = await apiFetch("GET", `/groups/${params.groupID}/tallies`);
  if (response.ok) return await response.json();
}

export async function createTally(groupID: string): Promise<Response> {
  const response = await apiFetch("POST", `/groups/${groupID}/tallies`);
  return response;
}

export async function updateTally({
  request,
  params,
}: ActionFunctionArgs): Promise<Response> {
  const groupID = params.groupID || "";
  const tallyID = params.trackerID || "";
  const payload = (await request.json()) as TallyUpdate;

  const response = await apiFetch(
    "PUT",
    `/groups/${groupID}/tallies/${tallyID}`,
    payload,
  );
  return response;
}
