import { AvatarTeamSm } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import NavBar from "@/components/ui/nav_bar";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { permissions } from "@/permissions";
import { GameStore } from "@/storage";
import { faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useSubmit } from "react-router-dom";
import { GameType, GroupRes, TeamRes } from "@/api/game";
import { TallyList } from "../tally/list";
import { ScorecardList } from "../scorecard/list";
import { TrackerBase } from "@/api/tracker";

export function TrackerList() {
  const params = useParams();
  const gameID = params.gameID || "";

  let type = new GameStore().get(gameID)?.type;

  switch (type) {
    case GameType.Tally:
      return <TallyList />;
    case GameType.Scorecard:
      return <ScorecardList />;
  }
}

type TrackerListCompProps = {
  trackers: TrackerBase[];
  groupData: GroupRes;
  teamsMap: { [id: string]: TeamRes };
  teams: string[];
  scores: number[][];
  allowRead?: boolean;
};
export function TrackerListComp({
  trackers,
  groupData,
  teamsMap,
  teams,
  scores,
  allowRead,
}: TrackerListCompProps) {
  const navSubmit = useSubmit();
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";

  const gameName = new GameStore().get(gameID)?.name;

  async function newScore() {
    navSubmit(null, {
      method: "post",
      action: `/games/${gameID}/groups/${groupID}/trackers`,
    });
  }
  async function deleteG() {
    navSubmit(null, {
      method: "post",
      action: `/games/${gameID}/groups/${groupID}`,
    });
  }

  return (
    <>
      <NavBar logo home>
        <div />
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Who's Winning?</h2>

        <Card>
          <CardHeader>
            <CardTitle>{gameName}</CardTitle>
            <div className="flex gap-1">
              {groupData.permission_level >= permissions.Admin && (
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="iconAlt" size="iconAlt">
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you absolutely sure?</DialogTitle>
                      <DialogDescription>
                        This action cannot be undone. This will permanently
                        delete this group and the scores within.
                      </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                      <Button variant="destructive" size="sm" onClick={deleteG}>
                        Confirm
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </CardHeader>
          <CardContent className="p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead></TableHead>
                  {teams.map((t) => (
                    <TableHead key={t}>
                      <AvatarTeamSm users={teamsMap[t].users} />
                    </TableHead>
                  ))}
                  {(groupData.permission_level >= permissions.Manager ||
                    allowRead) && <TableHead></TableHead>}
                </TableRow>
              </TableHeader>
              <TableBody>
                {trackers.map((tracker, i) => (
                  <TableRow key={tracker.id}>
                    <TableCell>
                      {new Date(tracker.created_at).toLocaleDateString(
                        undefined,
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        },
                      )}
                    </TableCell>
                    {teams.map((_, j) => (
                      <TableCell key={j}>{scores[j][i]}</TableCell>
                    ))}
                    {groupData.permission_level >= permissions.Manager ? (
                      <TableCell className="p-0">
                        <Link
                          to={`/games/${gameID}/groups/${groupID}/trackers/${tracker.id}`}
                          className="px-2 py-4"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Link>
                      </TableCell>
                    ) : (
                      allowRead && (
                        <TableCell className="p-0">
                          <Link
                            to={`/games/${gameID}/groups/${groupID}/trackers/${tracker.id}`}
                            className="px-2 py-4"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </TableCell>
                      )
                    )}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Totals</TableCell>
                  {teams.map((t, i) => (
                    <TableCell key={t}>
                      {scores[i].reduce((sum, current) => sum + current)}
                    </TableCell>
                  ))}
                  {(groupData.permission_level >= permissions.Manager ||
                    allowRead) && <TableCell></TableCell>}
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>

        {groupData.permission_level >= permissions.Manager && (
          <div className="flex justify-center">
            <Button onClick={newScore}>New Score</Button>
          </div>
        )}
      </div>
    </>
  );
}
