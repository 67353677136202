import {
  faPencil,
  faRightFromBracket,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/passwordless";
import { UserRes } from "@/api/user";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { DoubleQ } from "@/components/ui/double_letters";
import NavBar from "@/components/ui/nav_bar";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";

export default function ProfileView() {
  const navigate = useNavigate();
  const loaderData = useRouteLoaderData("profilecheck") as UserRes;

  async function logout() {
    await signOut();
    navigate("/login");
  }

  const buttons = [
    { to: "/profile/edit", icon: faPencil, text: "Edit profile" },
    // { to: "#", icon: faEnvelope, text: "Change email" },
    // { to: "#", icon: faComment, text: "Give feedback" },
  ];
  return (
    <>
      <NavBar back="/home" logo />
      <div className="flex flex-col items-center gap-4">
        <Avatar size="lg">
          <AvatarImage src={loaderData.avatarUrl} />
          <AvatarFallback>
            <DoubleQ size="lg" />
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col items-center">
          <p className="font-medium text-3xl">{loaderData.name}</p>
          <p className="pb-4 text-sm font-main text-brown/60">
            {loaderData.email}
          </p>
        </div>

        <div className="flex flex-col items-stretch">
          {buttons.map((btn, i) => (
            <Button key={i} asChild>
              <Link to={btn.to}>
                <FontAwesomeIcon icon={btn.icon} className="mr-2" /> {btn.text}
              </Link>
            </Button>
          ))}
          <Drawer>
            <DrawerTrigger asChild>
              <Button>
                <FontAwesomeIcon icon={faShare} className="mr-2" />
                Share
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>QR Code</DrawerTitle>
              </DrawerHeader>
              <img
                src="/images/pockettally_qr_code.png"
                alt="pocket tally logo"
              />
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button variant="outline">Close</Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
          <Button variant="destructive" onClick={logout}>
            <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
            Logout
          </Button>
        </div>
        <div className="text-xs underline font-main flex justify-center mt-6">
          <Link to="/terms" className="px-1 border-r border-brown">
            Terms of Service
          </Link>
          <Link to="/privacy" className="px-1">
            Privacy Policy
          </Link>
        </div>
      </div>
    </>
  );
}
