import { ScorecardTemplateRes, createScorecardTemplate } from "@/api/scorecard";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import FormError from "@/components/ui/form_error";
import { GhostInput } from "@/components/ui/input";
import NavBar from "@/components/ui/nav_bar";
import { Separator } from "@/components/ui/separator";
import { GameScorecardStore, GameStore } from "@/storage";
import {
  faArrowRight,
  faChevronLeft,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, ReactNode, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  ActionFunctionArgs,
  redirect,
  useLoaderData,
  useParams,
  useSubmit,
} from "react-router-dom";

export async function scorecardTemplateAction({
  request,
  params,
}: ActionFunctionArgs): Promise<Response> {
  const body = await request.json();
  const gameID = params.gameID || "";

  let templateID;
  if (body.intent == "create") {
    // Create template
    templateID = await createScorecardTemplate(gameID, body.payload);
  } else {
    templateID = body.template_id;
  }

  // Register template locally to this game
  new GameScorecardStore().set(gameID, templateID);

  // Next create the group
  return redirect(`/games/${gameID}/groups/create`);
}

export function ScorecardTemplate() {
  // Wizard for navigation
  const [wizard, wizardSetter] = useState(["list"]);
  function nextStep(step: string) {
    wizardSetter([...wizard, step]);
  }
  function prevStep() {
    let w = [...wizard];
    w.pop();
    wizardSetter(w);
  }

  // Every page knows it's own forward route(s)
  // Backwards navigation handled by the wizard
  const steps: { [id: string]: ReactNode } = {
    list: <ListScorecardTemplates nextStep={nextStep} />,
    create: <CreateScorecardTemplate prevStep={prevStep} />,
  };

  return <>{steps[wizard[wizard.length - 1]]}</>;
}

function ListScorecardTemplates({
  nextStep,
}: {
  nextStep: (step: string) => void;
}) {
  const navSubmit = useSubmit();

  const loaderData = useLoaderData() as ScorecardTemplateRes[];
  const params = useParams();
  const gameName = new GameStore().get(params.gameID || "")?.name;

  function pick(templateID: string) {
    navSubmit(
      { intent: "select", template_id: templateID },
      { method: "post", encType: "application/json" },
    );
  }
  return (
    <>
      <NavBar logo home>
        <div />
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">
          {gameName} Templates
        </h2>
        {loaderData.map((st) => (
          <Card key={st.id}>
            <CardHeader>
              <CardTitle>Categories</CardTitle>
              <Button
                variant="constructive"
                size="xs"
                onClick={() => pick(st.id)}
              >
                Pick
                <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
              </Button>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-1">
                {st.fields.map((f, i) => (
                  <Fragment key={i}>
                    {i != 0 && <Separator />}
                    <p className="font-main">{f.name}</p>
                  </Fragment>
                ))}
              </div>
            </CardContent>
          </Card>
        ))}
        <div className="flex justify-center">
          <Button onClick={() => nextStep("create")}>
            Create New Template
          </Button>
        </div>
      </div>
    </>
  );
}

type CategoryValues = {
  inputs: { value: string }[];
};

function CreateScorecardTemplate({ prevStep }: { prevStep: () => void }) {
  const navSubmit = useSubmit();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryValues>({
    defaultValues: {
      inputs: [{ value: "" }], // Start with one input field
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "inputs",
  });

  const onSubmit = (data: CategoryValues) => {
    navSubmit(
      { intent: "create", payload: data.inputs.map((x) => x.value) },
      { method: "post", encType: "application/json" },
    );
  };

  return (
    <>
      <NavBar logo>
        <Button variant="icon" size="icon" onClick={() => prevStep()}>
          <FontAwesomeIcon icon={faChevronLeft} className="text-3xl" />
        </Button>
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Create Template</h2>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-stretch gap-6"
        >
          <Card>
            <CardHeader>
              <CardTitle>Categories</CardTitle>
              <Button
                type="button"
                variant="constructive"
                size="xs"
                onClick={() => append({ value: "" })}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add Category
              </Button>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-1">
                {fields.map((f, i) => (
                  <Fragment key={f.id}>
                    {i != 0 && <Separator />}
                    <div className="flex justify-between items-center gap-2">
                      <GhostInput
                        type="text"
                        {...register(`inputs.${i}.value`, {
                          required: "This field is required.",
                        })}
                        placeholder="Enter category..."
                        pencil
                      />
                      <Button
                        type="button"
                        variant="iconAlt"
                        size="iconAlt"
                        onClick={() => remove(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                    <FormError error={errors.inputs?.[i]?.value} />
                  </Fragment>
                ))}
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-center">
            <Button>Create</Button>
          </div>
        </form>
      </div>
    </>
  );
}
