export function apiFetch(
  method: string,
  url: string,
  payload?: any,
  isFormData: boolean = false,
) {
  let options: RequestInit = { method: method };
  if (payload) {
    if (isFormData) {
      options = {
        ...options,
        body: payload,
      };
    } else {
      options = {
        ...options,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
    }
  }
  // TODO; catch offline issues and show offline page
  return fetch(import.meta.env.VITE_API_DOMAIN + url, options);
}
