import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";

import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router-dom";

import "./fonts.css";
import "./index.css";
import "./sentry";
import "./supertokens";

import { Root, Login } from "./routes/root";
import ErrorPage from "./error-page";
import Home from "./routes/home";
import FriendsList, { friendsLoader } from "./routes/friends/list";
import ProfileView from "./routes/profile/view";
import ProfileEdit from "./routes/profile/edit";
import GameCreate from "./routes/games/create/game_wizard";
import {
  createProfile,
  editProfile,
  getFriends,
  profileCheckLoader,
} from "./api/user";
import { createGame, deleteGroup, getGames } from "./api/game";
import {
  GroupCreate,
  groupCreateAction,
} from "./routes/games/create/group_wizard";
import ProfileCreate from "./routes/profile/create";
import { FriendsCreate, friendsCreateAction } from "./routes/friends/create";
import { createTracker, getTracker, updateTracker } from "./api/tracker";
import Privacy from "./routes/privacy";
import Terms from "./routes/terms";
import {
  ScorecardTemplate,
  scorecardTemplateAction,
} from "./routes/games/create/scorecard_wizard";
import { listScorecardTemplates } from "./api/scorecard";
import { TrackerList } from "./routes/games/trackers/list";
import { TrackerEdit } from "./routes/games/trackers/edit";

const router = createBrowserRouter([
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    element: <Login />,
    errorElement: <ErrorPage />,
    children: [
      ...getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
        PasswordlessPreBuiltUI,
      ]).map((r) => r.props),
    ],
  },
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/profile/create",
        element: <ProfileCreate />,
        action: createProfile,
      },
      {
        id: "profilecheck",
        loader: profileCheckLoader,
        children: [
          {
            path: "/",
            loader: () => redirect("/home"),
          },
          {
            path: "/home",
            loader: getGames,
            element: <Home />,
          },
          {
            path: "/profile",
            element: <ProfileView />,
          },
          {
            path: "/profile/edit",
            element: <ProfileEdit />,
            action: editProfile,
          },
          {
            path: "/friends",
            element: <FriendsList />,
            loader: friendsLoader,
          },
          {
            path: "/friends/create",
            element: <FriendsCreate />,
            action: friendsCreateAction,
          },
          {
            path: "/games/create",
            element: <GameCreate />,
            action: createGame,
          },
          {
            path: "/games/:gameID",
            children: [
              {
                path: "scorecard-template",
                element: <ScorecardTemplate />,
                loader: listScorecardTemplates,
                action: scorecardTemplateAction,
              },
              {
                path: "groups/create",
                element: <GroupCreate />,
                loader: getFriends,
                action: groupCreateAction,
              },
              {
                id: "gameroot",
                path: "groups/:groupID",
                loader: getTracker,
                action: deleteGroup,
                children: [
                  {
                    path: "trackers",
                    element: <TrackerList />,
                    action: createTracker,
                  },
                  {
                    path: "trackers/:trackerID",
                    element: <TrackerEdit />,
                    action: updateTracker,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
