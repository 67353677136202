import { UserRes } from "@/api/user";
import { Avatar, AvatarImage, AvatarTeamLg } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import FormError from "@/components/ui/form_error";
import NavBar from "@/components/ui/nav_bar";
import { Separator } from "@/components/ui/separator";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckedState } from "@radix-ui/react-checkbox";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";

type Inputs = {
  selectedTeams: UserRes[][];
};

type TeamProps = {
  prevStep: () => void;
  nextStep: (step: string) => void;
  friendState: [UserRes[], Dispatch<SetStateAction<UserRes[]>>];
  teamsState: [UserRes[][], Dispatch<SetStateAction<UserRes[][]>>];
};
export function TeamSelect(props: TeamProps) {
  const [users, _] = props.friendState;
  const [teams, teamsSetter] = props.teamsState;
  const usersToPick = users.filter(
    (u) =>
      !teams
        .flat()
        .map((u) => u.id)
        .includes(u.id),
  );
  const [teamPick, teamPickSetter] = useState(0);

  // When selecting users, reset teams
  useEffect(() => {
    teamsSetter([[]]);
  }, [users]);

  const {
    register,
    trigger,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>();

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) return;

    setValue("selectedTeams", teams);
    trigger("selectedTeams");
  }, [teams]);

  function userRow(u: UserRes) {
    return (
      <div className="flex items-center gap-2">
        <Avatar>
          <AvatarImage src={u.avatarUrl} />
        </Avatar>
        <p className="font-main font-medium leading-none">{u.name}</p>
      </div>
    );
  }

  function onCheck(user: UserRes, checked: CheckedState) {
    let newTeams = [...teams];
    if (checked) {
      newTeams[teamPick].push(user);
      teamsSetter(newTeams);
    } else {
      let i = newTeams[teamPick].findIndex((u) => u.id == user.id);
      newTeams[teamPick].splice(i, 1);
      teamsSetter(newTeams);
    }
  }

  return (
    <>
      <NavBar logo>
        <Button variant="icon" size="icon" onClick={() => props.prevStep()}>
          <FontAwesomeIcon icon={faChevronLeft} className="text-3xl" />
        </Button>
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Who's playing?</h2>
        <Drawer>
          <Card>
            <CardHeader>
              <CardTitle>Teams</CardTitle>
              <Button
                variant="constructive"
                size="xs"
                onClick={() => teamsSetter([...teams, []])}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add team
              </Button>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-1">
                {teams.map((t, i) => (
                  <Fragment key={i}>
                    {i != 0 && <Separator />}
                    <DrawerTrigger asChild>
                      <div
                        className="flex justify-between items-center"
                        onClick={() => teamPickSetter(i)}
                      >
                        {t.length == 0 ? (
                          <p className="text-sm">Add players to this team...</p>
                        ) : (
                          <AvatarTeamLg users={t} className="py-1" />
                        )}
                        <Button variant="iconAlt" size="iconAlt">
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </div>
                    </DrawerTrigger>
                  </Fragment>
                ))}
              </div>
              <input
                className="hidden"
                {...register("selectedTeams", {
                  validate: () =>
                    usersToPick.length == 0 ||
                    "Please place all users into teams.",
                })}
              />
              <div className="flex justify-center pt-2">
                <FormError error={errors.selectedTeams} />
              </div>
            </CardContent>
          </Card>

          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle>Pick</DrawerTitle>
            </DrawerHeader>
            <div className="flex flex-col gap-1 max-h-96 overflow-y-scroll">
              {users.map((u, i) => (
                <Fragment key={u.id}>
                  {teams[teamPick]
                    .concat(usersToPick)
                    .map((x) => x.id)
                    .includes(u.id) && (
                    <>
                      {i != 0 && <Separator />}
                      <label className="mx-1">
                        <div className="flex justify-between items-center">
                          {userRow(u)}
                          <Checkbox
                            defaultChecked={teams[teamPick]
                              .map((x) => x.id)
                              .includes(u.id)}
                            onCheckedChange={(b) => onCheck(u, b)}
                          />
                        </div>
                      </label>
                    </>
                  )}
                </Fragment>
              ))}
            </div>
            <DrawerFooter>
              <div className="flex justify-center">
                <DrawerClose asChild>
                  <Button variant="constructive" size="sm" className="w-28">
                    Add
                  </Button>
                </DrawerClose>
              </div>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <div className="flex flex-col items-center">
          <Button
            onClick={() => {
              firstUpdate.current = false;
              handleSubmit(() => props.nextStep("submit"))();
            }}
          >
            Start Game
          </Button>
        </div>
      </div>
    </>
  );
}
