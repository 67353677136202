import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import NavBar from "@/components/ui/nav_bar";
import { ReactNode } from "react";

function Title({ children }: { children: ReactNode }) {
  return <p className="text-sm font-semibold mt-2">{children}</p>;
}
function Para({ children }: { children: ReactNode }) {
  return <p className="text-xs mt-2">{children}</p>;
}
function List({ children }: { children: ReactNode }) {
  return (
    <ul className="list-disc list-inside ps-2 text-xs mt-2">{children}</ul>
  );
}

export default function Privacy() {
  return (
    <>
      <NavBar logo home>
        <div />
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">Privacy Policy</h2>

        <Card className="font-main">
          <CardHeader>
            <CardTitle>Introduction</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              This Privacy Policy outlines what you can expect from us regarding
              the collection, use, and protection of your personal information
              when you use our website or apps.
            </Para>
            <Para>
              We use your personal data to provide and improve the Service. By
              using the Service, you agree to the collection and use of
              information in accordance with this Privacy Policy.
            </Para>
          </CardContent>

          <CardHeader>
            <CardTitle>Last Updated</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>24 September 2024</Para>
          </CardContent>

          <CardHeader>
            <CardTitle>What information we collect, use, and why</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Title>Personal Data</Title>
            <Para>
              When you sign up for the Service, we may ask you to provide
              certain personal information, which may include but is not limited
              to:
            </Para>
            <List>
              <li>Display Name</li>
              <li>Email Address</li>
              <li>Profile Picture</li>
            </List>
            <Para>We use this personal data to:</Para>
            <List>
              <li>
                Provide and Maintain the Service: This includes ensuring the
                functionality of the Service and monitoring its usage.
              </li>
              <li>
                Manage Your Account: Your personal data allows us to handle your
                registration and provide access to the features and
                functionalities available to registered users.
              </li>
              <li>
                Communicate with You: We may use your information to contact you
                via email or push notifications with updates, important
                information, or other relevant communications about the Service.
              </li>
            </List>
            <Title>Usage Data</Title>
            <Para>
              Usage Data is collected automatically when using the Service. This
              is used to better understand user behavior, improve our services,
              and enhance your overall experience. The types of Usage Data we
              collect include, but are not limited to:
            </Para>
            <List>
              <li>
                Device Information: Details about the device you use to access
                our services, such as the type of device, operating system,
                browser type, and version.
              </li>
              <li>
                IP Address: The Internet Protocol (IP) address assigned to your
                device when connecting to the internet.
              </li>
              <li>
                Log Information: Information about your interactions with our
                service, including access times, pages viewed, links clicked,
                and the length of time spent on certain pages.
              </li>
              <li>
                Location Data: General geographic location information, derived
                from your IP address or device settings, such as your city or
                country.
              </li>
              <li>
                Cookies and Tracking Technologies: We may use cookies, web
                beacons, and other tracking technologies to collect information
                about your activity on our site, such as your preferences,
                visits to specific sections of the service, or whether you have
                opened an email we sent.
              </li>
            </List>
            <Title>Sharing Data</Title>
            <Para>
              This information may be shared in the following situations:
            </Para>
            <List>
              <li>With other users: when they connect with you in the app.</li>
              <li>
                With Service Providers: We may share your personal data with
                Service Providers to operate and analyze the use of our Service.
              </li>
            </List>
          </CardContent>

          <CardHeader>
            <CardTitle>Your Data Protection Rights</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              As a user of our services, you have certain rights regarding your
              personal data under applicable data protection laws. These rights
              may include:
            </Para>
            <List>
              <li>
                Access: The right to request access to the personal data we hold
                about you.
              </li>
              <li>
                Rectification: The right to request correction of inaccurate or
                incomplete personal data.
              </li>
              <li>
                Erasure: The right to request deletion of your personal data
                under certain circumstances.
              </li>
              <li>
                Restriction of Processing: The right to request that we limit
                the processing of your personal data in specific situations.
              </li>
              <li>
                Data Portability: The right to request a copy of your data in a
                commonly used format.
              </li>
            </List>
            <Para>
              For more detailed information on your data protection rights, you
              can visit the{" "}
              <a
                className="underline"
                href="https://ico.org.uk/global/privacy-notice/your-data-protection-rights/"
              >
                Information Commissioner’s Office (ICO) website
              </a>
            </Para>
          </CardContent>

          <CardHeader>
            <CardTitle>How long we keep information</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              We will retain any data only for as long as is necessary for the
              purposes set out in this Privacy Policy.
            </Para>
          </CardContent>

          <CardHeader>
            <CardTitle>Security</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              We take reasonable measures to help protect personal information
              from loss, theft, misuse and unauthorized access, disclosure,
              alteration and destruction.
            </Para>
          </CardContent>

          <CardHeader>
            <CardTitle>Changes to this Privacy Policy</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              We may update our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </Para>
            <Para>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </Para>
          </CardContent>

          <CardHeader>
            <CardTitle>Contact Us</CardTitle>
          </CardHeader>
          <CardContent className="py-0">
            <Para>
              If you have any questions about this Privacy Policy, you can
              contact us:{" "}
              <a
                className="underline"
                href="mailto:contact@mail.pockettally.app"
              >
                contact@mail.pockettally.app
              </a>
            </Para>
            <br />
          </CardContent>
        </Card>
      </div>
    </>
  );
}
