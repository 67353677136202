import { Button } from "@/components/ui/button";
import { Drawer, DrawerTrigger } from "@/components/ui/drawer";
import NavBar from "@/components/ui/nav_bar";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolsList from "../tools/menu";
import { useParams, useRouteLoaderData, useSubmit } from "react-router-dom";
import { GameStore } from "@/storage";
import { ScorecardGroupRes } from "@/api/scorecard";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { AvatarTeamSm } from "@/components/ui/avatar";
import { useState } from "react";
import { GhostInput } from "@/components/ui/input";
import { permissions } from "@/permissions";

export default function ScorecardEdit() {
  const navSubmit = useSubmit();
  const params = useParams();
  const gameID = params.gameID || "";
  const groupID = params.groupID || "";
  const trackerID = params.trackerID || "";

  const gameName = new GameStore().get(gameID)?.name;
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;
  const groupData = loaderData.group;
  const loaderScorecard = loaderData.scorecards.find((s) => s.id == trackerID);
  if (!loaderScorecard) throw Error("Scorecard not found.");
  const loaderScores = loaderScorecard.scores;

  const teamsMap = Object.assign(
    {},
    ...loaderData.group.teams.map((t) => ({ [t.id]: t })),
  );
  // Teams in an order
  const teams = Object.keys(teamsMap);

  const fieldsMap = Object.assign(
    {},
    ...loaderScores.map((s) => ({ [s.field_id]: s.field_name })),
  );
  // Fields in an order
  const fields = [...new Set(loaderScores.map((s) => s.field_id))];

  const [scores, scoresSetter] = useState<number[][]>(
    fields.map((f) =>
      teams.map(
        (t) =>
          loaderScores.find((s) => s.field_id == f && s.team_id == t)?.score ||
          0,
      ),
    ),
  );

  function update(i: number, j: number, score: number) {
    let newScores = [...scores];
    newScores[i][j] = score;
    scoresSetter(newScores);
  }
  function submit(e: React.FormEvent) {
    e.preventDefault();

    const payload = fields.flatMap((f, i) =>
      teams.map((t, j) => ({
        field_id: f,
        team_id: t,
        score: scores[i][j],
      })),
    );

    navSubmit(
      { scores: payload },
      { method: "put", encType: "application/json" },
    );
  }

  return (
    <>
      <NavBar back={`/games/${gameID}/groups/${groupID}/trackers`} logo>
        <Drawer>
          <DrawerTrigger asChild>
            <Button variant="icon" size="icon">
              <FontAwesomeIcon icon={faScrewdriverWrench} />
            </Button>
          </DrawerTrigger>
          <ToolsList teams={loaderData.group.teams} />
        </Drawer>
      </NavBar>

      <div className="flex flex-col items-stretch gap-6">
        <h2 className="text-xl font-medium text-center">{gameName}</h2>

        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                {teams.map((t) => (
                  <TableHead key={t}>
                    <AvatarTeamSm users={teamsMap[t].users} />
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {fields.map((f, i) => (
                <TableRow key={f}>
                  <TableCell>{fieldsMap[f]}</TableCell>
                  {teams.map((t, j) => (
                    <TableCell key={`${f}${t}`}>
                      <div>
                        <GhostInput
                          type="number"
                          className="max-w-20 [&>input]:text-center"
                          value={scores[i][j]}
                          onFocus={(e) => (e.target.value = "")}
                          onBlur={(e) =>
                            (e.target.value = scores[i][j].toString())
                          }
                          onChange={(e) =>
                            update(i, j, parseInt(e.currentTarget.value))
                          }
                          pencil={
                            groupData.permission_level >= permissions.Manager &&
                            scores[i][j] == 0
                          }
                          readOnly={
                            groupData.permission_level < permissions.Manager
                          }
                        />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Totals</TableCell>
                {teams.map((t, i) => (
                  <TableCell key={t}>
                    {scores
                      .map((field_scores) => field_scores[i])
                      .reduce((sum, current) => sum + current)}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          </Table>
        </Card>

        {groupData.permission_level >= permissions.Manager && (
          <div className="flex justify-center">
            <Button size="lg" onClick={submit}>
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
