import { TallyGroupRes } from "@/api/tally";
import { useRouteLoaderData } from "react-router-dom";
import { TrackerListComp } from "../trackers/list";

export function TallyList() {
  const loaderData = useRouteLoaderData("gameroot") as TallyGroupRes;
  const groupData = loaderData.group;

  const teamsMap = Object.assign(
    {},
    ...groupData.teams.map((t) => ({ [t.id]: t })),
  );

  // Teams in an order
  const teams = Object.keys(teamsMap);
  // Scores ordered by the teams
  const scores = teams.map((team) =>
    loaderData.tallies.map(
      (tally) => tally.scores.find((s) => s.team_id == team)?.score || 0,
    ),
  );

  return (
    <TrackerListComp
      trackers={loaderData.tallies}
      groupData={groupData}
      teamsMap={teamsMap}
      teams={teams}
      scores={scores}
    />
  );
}
