import { ScorecardGroupRes } from "@/api/scorecard";
import { useRouteLoaderData } from "react-router-dom";
import { TrackerListComp } from "../trackers/list";

export function ScorecardList() {
  const loaderData = useRouteLoaderData("gameroot") as ScorecardGroupRes;
  const groupData = loaderData.group;

  const teamsMap = Object.assign(
    {},
    ...groupData.teams.map((t) => ({ [t.id]: t })),
  );

  // Teams in an order
  const teams = Object.keys(teamsMap);
  // Scores ordered by the teams
  const scores = teams.map((team) =>
    loaderData.scorecards.map((scorecard) =>
      // Total the team score per scorecard
      scorecard.scores.reduce(
        (sum, current) => (current.team_id == team ? sum + current.score : sum),
        0,
      ),
    ),
  );

  return (
    <TrackerListComp
      trackers={loaderData.scorecards}
      groupData={groupData}
      teamsMap={teamsMap}
      teams={teams}
      scores={scores}
      allowRead
    />
  );
}
