import { useRegisterSW } from "virtual:pwa-register/react";
import { Button } from "./components/ui/button";

function ReloadPrompt() {
  const {
    needRefresh: [needRefresh, _],
    updateServiceWorker,
  } = useRegisterSW();

  return (
    <>
      {needRefresh && (
        <div className="w-screen fixed bottom-6 left-0 flex justify-center z-50">
          <div className="grow max-w-screen-sm p-2">
            <div className="font-main bg-white rounded-xl border-2 border-brown drop-shadow-md">
              <div className="flex justify-between items-center font-main">
                <p className="px-4 py-2">A new version is available.</p>
                <div className="flex">
                  {/*
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => setNeedRefresh(false)}
                  >
                    Close
                  </Button>
                  */}
                  <Button size="sm" onClick={() => updateServiceWorker(true)}>
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReloadPrompt;
