import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";
import FormError from "@/components/ui/form_error";
import NavBar from "@/components/ui/nav_bar";
import { Button } from "@/components/ui/button";
import { GameRes, GameType, searchGames } from "@/api/game";
import { Separator } from "@/components/ui/separator";

function debounce<T extends (...args: any[]) => void>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout;

  return function (...args: Parameters<T>): void {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export type GameStateT = {
  name: string;
  type: string;
};

type gameSelectProps = {
  nextStep: (step: string) => void;
  gameState: MutableRefObject<GameStateT>;
};
export function GameTypeComp(props: gameSelectProps) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<GameStateT>({ defaultValues: props.gameState.current });

  // These names need to match the BE
  const gameTypes = [
    {
      name: GameType.Tally,
      icon: "/images/tally_icon.svg",
      step: "submit",
      desc: "A simple count of scores.",
    },
    {
      name: GameType.Scorecard,
      icon: "/images/scorecard_icon.svg",
      step: "submit",
      desc: "A table of scores for games with scoresheets.",
    },
    // {
    //   name: GameType.Tournament,
    //   icon: "/images/tournament_icon.svg",
    //   step: "submit",
    //   desc: "A battle between players.",
    // },
  ];

  const searchRef = useRef<HTMLDivElement>(null);
  const [search, searchSetter] = useState<GameRes[]>([]);
  const [showSearch, showSearchSetter] = useState(false);
  // Hide search when user clicks off it
  useEffect(() => {
    function handleClickOff(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        showSearchSetter(false);
      }
    }
    document.addEventListener("mousedown", handleClickOff);
    return () => {
      document.removeEventListener("mousedown", handleClickOff);
    };
  }, [searchRef]);

  async function _gameSearch(s: string) {
    try {
      const games = await searchGames(s);
      searchSetter(games);
      showSearchSetter(true);
    } catch {}
  }
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      _gameSearch(searchQuery);
    }, 500),
    [],
  );
  function gameSearch(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.currentTarget.value) searchSetter([]);
    else debouncedSearch(e.currentTarget.value);
  }
  function searchSelect(g: GameRes) {
    setValue("name", g.name);
    setValue("type", g.type);
    showSearchSetter(false);
  }

  function submit(data: GameStateT) {
    props.gameState.current = data;
    let type = gameTypes.find((gt) => gt.name == data.type);
    if (type) props.nextStep(type.step);
  }

  return (
    <>
      <NavBar back="/home" logo />

      <div className="flex flex-col items-center gap-6">
        <h2 className="text-xl font-medium">Start Scoring</h2>
        <form
          onSubmit={handleSubmit(submit)}
          className="flex flex-col gap-6 items-center w-full"
        >
          <div className="flex flex-col items-center">
            <div className="relative">
              <Input
                className="relative z-20 peer"
                autoComplete="off"
                placeholder="Enter game name..."
                onFocus={() => showSearchSetter(true)}
                {...register("name", {
                  required: "This field is required.",
                  onChange: gameSearch,
                })}
              />
              {search.length != 0 && showSearch && (
                <div
                  ref={searchRef}
                  className="absolute z-10 top-8 left-0 w-full font-main"
                >
                  <div className="flex flex-col gap-1 pt-4 pb-2 px-2 bg-white rounded-b-lg ring-2 ring-brown">
                    {search.map((g, i) => (
                      <Fragment key={g.id}>
                        {i != 0 && <Separator />}
                        <div
                          onClick={() => searchSelect(g)}
                          className="flex justify-between py-1 px-2 cursor-pointer rounded-md hover:bg-slate-200"
                        >
                          <p>{g.name}</p>
                          <p>{g.type}</p>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <FormError error={errors.name} />
          </div>
          <p>Pick a score type...</p>
          <div className="flex flex-wrap gap-6 justify-around w-full">
            {gameTypes.map((t) => (
              <label key={t.name} className="flex">
                <input
                  type="radio"
                  className="hidden peer"
                  {...register("type", {
                    required: "A score type is required",
                  })}
                  value={t.name}
                />
                <div
                  className="peer-checked:ring-4 peer-checked:ring-brown
                  bg-white rounded-xl shadow shadow-white p-4 flex flex-col items-center max-w-36 sm:max-w-40 cursor-pointer"
                >
                  <div className="h-14 w-14 bg-brown rounded-full p-2 flex justify-center items-center">
                    <img src={t.icon} className="h-8" />
                  </div>
                  <h3 className="font-medium">{t.name}</h3>
                  <p className="text-xs text-center">{t.desc}</p>
                </div>
              </label>
            ))}
          </div>
          <FormError error={errors.type} />
          <Button>Start Game</Button>
        </form>
      </div>
    </>
  );
}
